<template>
    <div style="background-color: white;">
        <ScrollPanel style="width: 100%;" :style="{ 'height': windowHeight + 'px' }">
            <div :v-if="Goster" style="margin-bottom:10px; border:0px solid #999999; padding:0px; margin:20px">
                <div style="margin-bottom:10px; display:none"><strong style="font-size:10px; color:blue"> {{
            this.MukellefInfo.HesapSahip }} -
                        {{ this.MukellefInfo.VKN }}</strong></div>
                <div style="border:0px solid #EEEEEE; height:980px;  width:660px;" align="left">
                    <strong style="color:red; display:none">BAĞKUR</strong>
                    <div style="display:none"><strong>--1 </strong> : </div>
                    <strong style="color:red; display:none">BAĞKUR</strong>
                    <div style="display:none"><strong>--1 </strong> : </div>
                    <strong style="color:red; display:none">BAĞKUR</strong>
                    <div style="display:none"><strong>--1 </strong> : </div>
                    <strong style="color:red; display:none">MUHASEBE</strong>
                    <div style="display:none"><strong>--1 </strong> : </div>
                    <strong style="color:red; display:none">MUHASEBE</strong>
                    <div style="display:none"><strong>--1 </strong> : </div>
                    <strong style="color:red; display:none">MUHASEBE</strong>
                    <div style="display:none"><strong>--1 </strong> : </div>
                    <strong style="color:red; display:none">MUHASEBE</strong>
                    <div style="display:none"><strong>--1 </strong> : </div>
                    <strong style="color:red; display:none">MUHASEBE</strong>
                    <div style="display:none"><strong>--1 </strong> : </div>
                    <span style="display:none">1949 - 1 - </span>
                    <span style="display:none">1949 - 2 - </span>
                    <div align="left">
                        <table border="0" width="100%" cellspacing="0" cellpadding="0">
                            <tbody>
                                <tr>
                                    <td width="110" align="left" valign="top"><img
                                            src="../../../assets/logo/malimüsavir.jpg" width="80" height="80"
                                            alt="GelsinTeknik" /></td>
                                    <td align="left" valign="middle" style="line-height:14px; color:#193598"><strong
                                            style="font-size:24px; color:#193598">{{
            this.MuhasebeInfo.HesapSahip }}</strong><br>
                                        {{ this.MuhasebeInfo.Unvan }}</td>
                                    <td align="right" valign="bottom" style="line-height:18px; color:#666666">
                                        Telefon: {{ this.MuhasebeInfo.TelefonNo }} <br>
                                        GSM: {{ this.MuhasebeInfo.CepTelefon1 }} <br>
                                        GSM: {{ this.MuhasebeInfo.CepTelefon2 }} <br>
                                        {{ this.MuhasebeInfo.MailAdres }} <br>
                                        {{ this.MuhasebeInfo.WebAdres }} </td>
                                </tr>
                            </tbody>
                        </table>
                        <div align="left" style="margin-top:40px; margin-bottom:30px;  margin-left:110px;"><strong
                                style="font-size:18px; color:#000">VERGİ ÖDEME ÇİZELGESİ</strong></div>
                    </div>
                    <div style="margin-bottom:5px; margin-left:110px;"><strong style="font-size:12px; color:#193598">{{
            this.MukellefInfo.AdSoyad }}</strong></div>
                    <table border="0" cellspacing="0" cellpadding="0"
                        style="border-bottom:1px solid #EEEEEE;  margin-left:110px;" align="center">
                        <tbody>
                            <tr>
                                <td align="left" width="120" valign="middle" height="24"><strong>ÖDEME TÜRÜ</strong>
                                </td>
                                <td align="center" width="110" valign="middle"><strong>ÖDEME TARİHİ</strong></td>
                                <td align="right" width="90" valign="middle"><strong>DÖNEMİ</strong></td>
                                <td align="right" width="100" valign="middle"><strong>TUTAR</strong>&nbsp;&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                    <table border="0" cellspacing="0" cellpadding="0"
                        style="border-bottom:1px solid #EEEEEE;  margin-left:110px;" align="center">
                        <!-- <tbody>
                            <tr>
                                <td align="left" width="120" valign="middle" height="24">&nbsp;MUHASEBE</td>
                                <td align="center" width="110" valign="middle">27.06.2024</td>
                                <td align="right" width="90" valign="middle">2024/05</td>
                                <td align="right" width="100" valign="middle">1.200,00&nbsp;TL&nbsp;</td>
                            </tr>
                        </tbody> -->

                        <tbody>
                            <template v-for="(value, key) in this.MukellefOdemeInfo" :key="key.id">
                                <tr>
                                    <td align="left" width="120" valign="middle" height="24">{{ value.Tur }}</td>
                                    <td align="center" width="110" valign="middle">{{ value.OdemeTarih }}</td>
                                    <td align="right" width="90" valign="middle">{{ value.Donem }}</td>
                                    <td align="right" width="100" valign="middle">{{ value.Tutar }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <table border="0" cellspacing="0" cellpadding="0"
                        style="border-bottom:0px solid #EEEEEE;  margin-left:110px; margin-top:4px">
                        <tbody>
                            <tr>
                                <td align="left" width="100" valign="middle"></td>
                                <td align="left" width="100" valign="middle"></td>
                                <td align="right" width="120" valign="middle"><strong>TOPLAM</strong></td>
                                <td align="right" width="100" valign="middle"><strong>{{ this.ToplamTutar }}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td align="left" valign="middle"></td>
                                <td align="left" valign="middle"></td>
                                <td align="right" valign="middle">&nbsp;</td>
                                <td align="right" valign="middle">&nbsp;</td>
                            </tr>
                            <tr>
                                <td align="left" valign="middle"></td>
                                <td align="left" valign="middle"></td>
                                <td align="right" valign="middle">&nbsp;</td>
                                <td align="right" valign="middle">&nbsp;</td>
                            </tr>
                            <tr>
                                <!-- YAPILACAK
                                <td colspan="4" align="left" valign="middle">
                                    <div style="border:1px solid  #666666; padding:10px">Bu ödeme çizelgesi,
                                        <strong>10.07.2024 12:25:39</strong> tarihinde aşağıdaki,<br>
                                        E-Posta adreslerine ve GSM numaralarına gönderilmiştir.<br>
                                        <br>
                                        05396764092<br>
                                        <br>
                                        o.safak01@gmail.com<br>
                                    </div>
                                    <title>ÖMER ŞAFAK - S.M.M.M. YUSUF INCESU</title>
                                </td> -->
                            </tr>
                            <tr>
                                <td colspan="4" align="left" valign="middle">
                                    <p><br>
                                        <br>
                                        <br>
                                        &Ouml;demesini banka havalesi ile yapacak m&uuml;şteriler i&ccedil;in, <br>
                                        <strong>Banka Hesap Bilgileri</strong><br>
                                        {{ this.MuhasebeInfo.BankaSube }} <br>
                                        Hesap Sahibi: <strong>{{ this.MuhasebeInfo.HesapSahip}} </strong><br>
                                        {{ this.MuhasebeInfo.Iban}}
                                        <br>
                                        <br>
                                        *
                                        Eft &ouml;demelerini en az 1 g&uuml;n &ouml;nceden yapınız.
                                    </p>
                                    <p>&nbsp;</p>
                                    <p><span style="line-height:24px; color:#193598">
                                            <img src="../../../assets/logo/logo1.png" alt="GelsinTeknik" width="200"
                                                height="47" border="0" title="GelsinTeknik&reg;">
                                        </span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </ScrollPanel>
    </div>
</template>

<script>
import GlobalServis from "../../../services/GlobalServis";

export default {
    data() {
        return {
            Goster: false,
            MuhasebeInfo: [],
            MukellefOdemeInfo: [],
            MukellefInfo: [],
            ToplamTutar : null,
            windowHeight: window.innerHeight,
        };
    },
    methods: {
        InitServis() {
            let data = {
                Donem: this.$route.query.donem,
                VKN: this.$route.query.vk,
                pSirket: this.$route.query.ps,
            };
            console.log(data);
            GlobalServis.GlobalServis("POST", "GetBildirimSayfaInfo", data).then(
                (res) => {
                    if (res.status == 200) {
                        this.MuhasebeInfo = res.data.MuhasebeInfo[0];
                        this.MukellefOdemeInfo = res.data.MukellefOdemeInfo;
                        this.MukellefInfo = res.data.MukellefInfo[0];
                        this.ToplamTutar = res.data.ToplamTutar[0].Toplam;
                        this.Goster = true;
                    } else if (res.status == 400) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Hata",
                            detail: "Önemli bir hata meydana geldi",
                            life: 3000,
                        });
                    } else {
                        this.$toast.add({
                            severity: "warn",
                            summary: "Uyarı",
                            detail: "Takip numaranız hatalıdır",
                            life: 5000,
                        });
                    }
                }
            )
        },
        onResize() {
            this.windowHeight = window.innerHeight
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })

    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
    created() {
        this.InitServis();
    },
    components: {
    },
};
</script>
<style lang="scss" scoped>
body,
* {
    margin: 0px;
    padding: 0px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    color: #666666;
    line-height: 20px;
}

div,
ul,
ol,
li,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
fieldset,
legend,
input {
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #666666;
}

a {
    color: #0088cc;
    text-decoration: none;
    outline-style: none;
}

a:hover {
    text-decoration: underline;
}

a.referal {
    text-decoration: none;
    color: #0066FF;
}

a.referal:hover {
    text-decoration: underline;
}

a.website {
    text-decoration: none;
    color: #009900;
}

a.website:hover {
    text-decoration: underline;
}

a.arama {
    text-decoration: none;
    color: #FFFFFF;
    background-color: #009900;
}

a.arama:hover {
    text-decoration: underline;
}

#tablospad table td {
    border-collapse: collapse;
    border-bottom: 1px solid #CCCCCC;
    border-left: 1px solid #CCCCCC;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.intLoop {
    font-weight: bold;
}

.tarih {
    font-size: 16px;
    font-weight: bold;
}
</style>